<template>
  <div class="notification has-text-centered is-fullheight">
    You don't have access to this domain. Click <a href="https://login.roelink.eu">here</a> to go back to the SSO.
  </div>
</template>

<script>
export default {
  name: "Forbidden"
}
</script>

<style scoped>

</style>
